import React from "react";
import Logo from "../asset/Logo.png";

export default function Footer() {
  return (
    <div className="footerContainer">
      <div className="footer">
        <img className="logo" src={Logo} alt="logo" />

        <div className="bottom">
          <span>@ Copyright 2022 Roundproxies</span>
          <a
            target={"_blank"}
            rel="noopener noreferrer"
            href="https://twitter.com/Roundproxies"
          >
            Twitter
          </a>
          <a
            target={"_blank"}
            rel="noopener noreferrer"
            href="https://w0fl1xyamiq.typeform.com/to/REFgwih7"
          >
            Join our Waitlist
          </a>
        </div>
      </div>
    </div>
  );
}
