import "./App.css";
import Footer from "./components/Footer";
import SectionFour from "./components/SectionFour";
import SectionOne from "./components/SectionOne";
import SectionThree from "./components/SectionThree";
import SectionTwo from "./components/SectionTwo";

function App() {
  return (
    <>
      <div className="App">
        <SectionOne />
        <SectionTwo />
        <SectionThree />
        <SectionFour />
      </div>

      <Footer />
    </>
  );
}

export default App;
