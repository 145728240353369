import React from "react";
import Resi from "../asset/Resi.png";
import Isp from "../asset/Isp.png";
import Zala from "../asset/Zala.png";

const Item = ({ name, image, upcoming }) => (
  <div className={`item ${upcoming ? "upcoming" : ""}`}>
    <p>{name}</p>
    <img src={image} alt="itemImg" />
  </div>
);

export default function SectionTwo() {
  return (
    <div className="container itemsContainer">
      <Item
        name={
          <p>
            RESIDENTIALS <span>v2.0</span>{" "}
          </p>
        }
        image={Resi}
      />
      <Item
        name={
          <p>
            HYBRID ISPS <span>v1.4</span>{" "}
          </p>
        }
        image={Isp}
      />
      <Item
        upcoming
        name={
          <p>
            UPDATES <span>24hrs</span>{" "}
          </p>
        }
        image={Zala}
      />
    </div>
  );
}
