import React from "react";

export default function SectionFour() {
  return (
    <div className="container">
      <div className="span-3 dash">
        <div className="flex">
          <span>ROUNDPROXIES</span>
          <span>Dashboard</span>
        </div>

        <button
          onClick={() => {
            window.open(
              "https://w0fl1xyamiq.typeform.com/to/REFgwih7",
              "_blank",
              "noopener,noreferrer"
            );
          }}
          className="support"
        >
          Join our waitlist
        </button>
      </div>
    </div>
  );
}
