import React from "react";
import AnimatedNumbers from "react-animated-numbers";

export default function SectionThree() {
  const [num, setNum] = React.useState(94.9);

  return (
    <div className="container">
      <div className="span-3 center">
        <span>
          <AnimatedNumbers
            animateToNumber={num}
            fontStyle={{ fontSize: "10.625rem" }}
            configs={(number, index) => {
              return { mass: 1, tension: 230 * (index + 1), friction: 140 };
            }}
          />
          <span>%</span>
        </span>

        <p>
          of our users are more successful compared to other ISP Proxy
          Providers.
        </p>
      </div>
    </div>
  );
}
