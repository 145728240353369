import React from "react";
import Logo from "../asset/Logo.png";

export default function SectionOne() {
  return (
    <div className="container">
      <div className="span-2">
        <div className="brand">
          <img className="logo" src={Logo} alt="logo" />
        </div>
        <div className="smText">
          Upgrading your <br /> Residentials and Hybrid ISPs <br />
          success-flow
        </div>
      </div>

      <div className="map">
        <button
          className="support"
          onClick={() => {
            window.open(
              "https://roundproxies.notion.site/Supported-Regions-e79e88a465fd45baa81cc23bc589c5f4",
              "_blank",
              "noopener,noreferrer"
            );
          }}
        >
          Worldwide Support
        </button>
      </div>
    </div>
  );
}
